import React from "react"
import PostCard from "./PostCard"
import styled from "styled-components"

const Posts = ({ posts }) => {
  return (
    <>
      <PostContainer>
        <PostWrapper>
          {posts.map(post => {
            return <PostCard key={post.id} {...post}></PostCard>
          })}
        </PostWrapper>
      </PostContainer>
    </>
  )
}

export default Posts

export const PostContainer = styled.div`
  padding-top: 48px;
  padding-bottom: calc(5vh);

  @media screen and (max-width: 500px) {
    padding-bottom: calc(30vh);
  }
`

export const PostWrapper = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-auto-rows: minmax(300px, auto);
  margin: 0 auto;
  width: 100%;
  max-width: 1000px;
  padding: 32px;
  text-align: center;

  @media screen and (max-width: 480px) {
    padding: 8px;
  }
`
