import React from "react"
import Ad from "../adsense"
import "./HeroSection.css"

function HeroSection({ location }) {
  return (
    <>
      <div className="hero-container">
        <div className="hero-content">
          <h1>Let's Learn Together</h1>
          <p>Creating content about design and code</p>
        </div>
        <Ad currentPath={location} adClass="adsbygoogle hero__ad" />
      </div>
    </>
  )
}

export default HeroSection
