import React from "react"
import Image from "gatsby-image"
import { Link } from "gatsby"

const PostCard = ({ frontmatter }) => {
  const { slug, image, title, path, alt } = frontmatter

  return (
    <>
      <div className="card__container">
        <Link to={`/${path}/${slug}`} className="card__link">
          <Image
            fluid={image.childImageSharp.fluid}
            className="card__img"
            alt={alt}
          />
          <div className="card__info">
            <h2 className="card__title">{title}</h2>
          </div>
        </Link>
      </div>
    </>
  )
}

export default PostCard
