import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import HeroSection from "../components/HeroSection"
import Posts from "../components/Posts"
import SEO from "../components/seo"
import "../styles/main.css"

const Home = ({ data }) => {
  const {
    allMdx: { nodes: posts },
  } = data
  return (
    <Layout>
      <SEO title="Home" />
      <HeroSection />
      <Posts posts={posts} key={posts.id} />
    </Layout>
  )
}

export default Home

export const query = graphql`
  {
    allMdx(
      filter: { fileAbsolutePath: { regex: "/posts/" } }
      sort: { order: DESC, fields: frontmatter___date }
      limit: 6
    ) {
      nodes {
        frontmatter {
          alt
          title
          path
          slug
          date(formatString: "MMMM Do, YYYY")
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        id
      }
    }
  }
`
